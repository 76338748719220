import React from "react";
//Styles CSS
import './SuccessSection.css';
import './AboutUsStyle.css';
//Images
import Success1 from '../../img/success-1.jpg';
import Success2 from '../../img/success-2.jpg';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const SuccessSection = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="success-container container">
            <div className="success-content">
                <h2 className="success-title m5-title">Success</h2>
                <p className="justify-p">The win-win formula has resulted in success for both the lender and borrower.
                Most of their clients have already stopped borrowing from them because they have sufficient capital already. Others still continue to borrow from them for their expansion like a sari-sari store has grown to a mini-grocery and needs additional capital. Others need new freezers and new equipment to support their expansion.</p>
                <p className="justify-p">Because of the good business, 80 percent of its franchises have 2-3 multiple branches.</p>
                <p className="justify-p">Soliman himself has been so passionate and focus. When he thinks of something, he won’t stop until he achieves his goal. He would send messages to his people even in the wee hours of the night for fear he might forget them after.</p>
                <p className="justify-p">His people undergo intensive training. The collectors or the credit analysts must undergo a rigid 21-day training including actual exposure in the field. He prefers to accept collectors with no experience.</p>
                <p className="justify-p">“I want my team members onboard because I need their support,” he adds.
                Soliman knows that 90 percent of entrepreneurs gave up on the first set of trials. But, he said, they did not think that trials normally come during the first phase of a project. Once that is hurdled, the next phase would be easier.</p>
                <p className="justify-p">“It is about your goals and putting the necessary hardwork to achieve them. Never surrender because trials will always be there, you will encounter difficulties but never give up,” he advises young entrepreneurs.</p>
                <p className="justify-p">For instance, the ultimate challenge of a lending company is retention of people. He solved this problem by raising the salaries of people and giving them fringe benefits, incentives and monthly rice allowance. Their collectors are provided with motorcycles. They hold team building activities for his 100 people. The entire network, including the franchises, has 400.</p>
                <p className="justify-p">“If you are good to your people and they are happy, this would be evident in their performance. You might think you have earned more from scrimping on the benefits for employees you are wrong because if you equate that to income, it could have been higher had you given more to your people,” says Soliman.</p>
                <p className="justify-p">His people, particularly the core team has a bigger voice and he listens to them and if he likes the idea, he would immediately allow its implementation. LT & G can act and adjust quickly because they are small and agile. Now, they are putting up an office in Manila because they already have 20 branches here.</p>
                <p className="justify-p">“I let my people shine and if I hire someone, he has to fill a specific need of the company. So, I always encourage them,” says Soliman, who sometimes runs his company remotely.</p>
            </div>
            <div className="success-img">
                <img src={Success1} alt="success 1 img" />
                <img src={Success2} alt="success 2 img" />
            </div>
        </motion.div>
    )
}

export default SuccessSection;