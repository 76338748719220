import React from 'react';
//Image
import FranchiseImg2 from '../../img/franchising-img2.png'
//Style CSS
import './Franchise2nd.css'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const Franchise2nd = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="container franchising-cont">
            <div className="fr-flex-cont">
                <div className="fr-sec-cont">
                    <h2 className="fr-main-h2">WHO DO WE WANT?</h2>
                    <ul>
                        <li>Passionate Entrepreneurs with attention to details</li>
                        <li>Determined to build their market share and grow with LT&G Credit Line</li>
                        <li>Follows rules and standards strictly</li>
                        <li>Values and virtues aligned with LT&G Credit Line</li>
                        <li>With adequate financial capability</li>
                    </ul>
                </div>
                <div className="franchising-cont-img">
                    <img className="franchising-img fr-sec-img" src={FranchiseImg2} alt="franchise" />
                </div>
            </div>
        </motion.div>
    )
}

export default Franchise2nd