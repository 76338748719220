import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

export const useScroll = () => {
    const controls  = useAnimation();
    const [element, view] = useInView({threshold: [0.2, 0.4, 0.6, 0.8, 1], /* triggerOnce: true */});
    if(view){
        controls.start("show");
    } /* else {
        controls.start("hidden");
    } */
    return [element, controls];
};