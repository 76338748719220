import SanMaria1 from '../img/santa-maria-1.jpg';
import SanMaria2 from '../img/santa-maria-2.jpg';
import SanMaria3 from '../img/santa-maria-3.jpg';
import SanMaria4 from '../img/santa-maria-4.jpg';
import SanMaria5 from '../img/santa-maria-5.jpg';
import SanMaria6 from '../img/santa-maria-6.jpg';
import SanMaria7 from '../img/santa-maria-7.jpg';
import SanMaria8 from '../img/santa-maria-8.jpg';
import SanMaria9 from '../img/santa-maria-9.jpg';
import SanMaria10 from '../img/santa-maria-10.jpg';
import SanMaria11 from '../img/santa-maria-11.jpg';
import SanMaria12 from '../img/santa-maria-12.jpg';
import SanMaria13 from '../img/santa-maria-13.jpg';
import SanMaria14 from '../img/santa-maria-14.jpg';


// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: SanMaria1
    },
    {
        id: 2,
        image: SanMaria2
    },
    {
        id: 3,
        image: SanMaria3
    },
    {
        id: 4,
        image: SanMaria4
    },
    {
        id: 5,
        image: SanMaria5
    },
    {
        id: 6,
        image: SanMaria6
    },
    {
        id: 7,
        image: SanMaria7
    },
    {
        id: 8,
        image: SanMaria8
    },
    {
        id: 9,
        image: SanMaria9
    },
    {
        id: 10,
        image: SanMaria10
    },
    {
        id: 11,
        image: SanMaria11
    },
    {
        id: 12,
        image: SanMaria12
    },
    {
        id: 13,
        image: SanMaria13
    },
    {
        id: 14,
        image: SanMaria14
    }
];