import React from 'react';
//Sidebar Component
import FrSidebar from '../FranchiseProgComponents/FrSidebar.js'
//Style CSS
import './FrProg.css';
//Images
import MarketingMain from '../../img/marketing.jpg';
import Marketing1 from '../../img/marketing-1.jpg';
import Marketing2 from '../../img/marketing-2.jpg';
import Marketing3 from '../../img/marketing-3.jpg';

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'

import { Link } from "react-router-dom";

const MarketingSupport = () => {
    return(
    <div className="container frprog-cont">
        <FrSidebar/>
        <div className="frprog-cont-info">
            <motion.div
                variants={fadeAnimation}
                initial='hidden'
                animate='show'
                exit='exit'  
            >
                <img className="frprog-img" src={MarketingMain} alt="main" />
                <h2 className="frprog-title">MARKETING SUPPORT</h2>
                <div className="frprog-cont-box">
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">OPENING WEEK MARKETING DRIVE WITH LT&G HEAD OFFICE TEAM</h3>
                        <img className="frprog-img" src={Marketing1} alt="opening week marketing drive" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">SOCIAL MEDIA ADVERTISING</h3>
                        <img className="frprog-img" src={Marketing2} alt="social media advertising" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">FLYER LAYOUT AND DESIGN</h3>
                        <img className="frprog-img" src={Marketing3} alt="flyer layout and design" />
                    </div>
                </div>
            </motion.div>
            <div className="left-right-btn">
                <div className="next-post-btn">
                    <Link to="/continuing-support"><HiOutlineArrowNarrowLeft/> Prev</Link>
                </div>
                <div className="next-post-btn">
                    <Link to="/franchise-training">Next <HiOutlineArrowNarrowRight/></Link>
                </div>
            </div>
        </div>
    </div>
    )
}

export default MarketingSupport;