import React from 'react';
import {Link} from 'react-router-dom';
import '../components/NoMatch.css';

import { motion } from 'framer-motion';
import { pageAnimation, titleAnimation, fade } from '../components/animation';

const NoMatch = () => {
    return(
        <motion.div 
            className="no-match-cont"
            variants={pageAnimation}
            initial='hidden'
            animate='show'
            exit='exit'
        >
            <div className="no-match-box" >
                <motion.h1 variants={titleAnimation}>404</motion.h1>
                <motion.h2 variants={titleAnimation}>Oops! That page can not be found.</motion.h2>
                <motion.h3 variants={titleAnimation}>Sorry, but the page you are looking for does not exist.</motion.h3>
                <motion.div className="btn-cont" variants={fade}>
                    <Link to="/">Go to home page</Link>
                </motion.div>
            </div>
        </motion.div>
    )
}

export default NoMatch;