import React from 'react';
//Style CSS
import './CompanySection.css';
import './AboutUsStyle.css';
//Image
import Company from '../../img/company.jpg';
//Youtube Embed
import YoutubeEmbed from "../../components/HomeComponents/YoutubeEmded";

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const CompanySection = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="company-container container">
            <div className="company-box">
                <img className="company-img" src={Company} alt="the company" />
                <div className="company-yt">
                    <YoutubeEmbed embedId="Pofftln2YrE" />
                </div>
                <div className="company-yt yt-2">
                    <YoutubeEmbed embedId="3icSo_3zTPo" />
                </div>
            </div>
            <div>
                <h2 className="company-title m5-title">The <span>Company</span></h2>
                <p className="justify-p">
                    Soliman, who came from a modest family in Bicol put up his first micro lending branch in Legazpi in 2014. With an initial capitalization of P1 million, he operates the micro lending enterprise with his wife Jessamine and three collectors.
                </p>
                <p className="justify-p">
                    According to Soliman, the need for micro financing is huge. In Bicol alone, there are around 40 lending companies catering to different markets such as teachers and pensioners. But LT&G chose to serve the micro enterprises because they feel they need to intervene for the reason that this sector already is being held captive by loan sharks.
                </p>
                <p className="justify-p">
                    Their borrowers are very micro or backyard enterprises with minimum loans ranging from P5,000 and maximum of P50,000 or an average of P15,000 to P20,000.
                </p>
                <p className="justify-p">
                    Their clients include small sari-sari stores, market vendors, carinderia, vulcanizing shops, photo copiers, salons, small bakeries, hardware stores, meat shops, and fruit shops.
                </p>
                <p className="justify-p">
                    “This is the unbanked sector and are very vulnerable to loan sharks. Without LT&G, they go to these loan sharks. It is sad as they fall into heavy debt because most of their earnings just go to pay the interest. So, they remain poor. In our case, we want them to be our partners for growth and we are extremely passionate about this mission,” he adds.
                </p>
                <p className="justify-p">
                    LT&G offers an interest rate that is way below the 20 percent interest rate being peddled by five-six loan sharks. They do not give their clients a hard time. The only requirement is a barangay permit and credit investigation after which the loans are released in 2-3 days. 
                </p>
                <p className="justify-p">
                    “We have to validate that they indeed have an existing business,” he adds. Micro lending is not easy. Soliman said there is a science in doing this business.
                </p>
                <p className="justify-p">
                    “You have to study how to collect the money on a daily basis. We have a system to validate, we have cash flow analysis. We teach our clients to validate how much to allow a borrower so that it is within his paying capacity. If the validation is wrong, the problem will immediately show, so we need to establish the cash flow every day. So, there is science, and the IT system is the heart of our operation,” he adds.
                </p>
            </div>
        </motion.div>
    )
}

export default CompanySection;