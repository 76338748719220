import React from 'react';
//Styles CSS
import './ListTestimonials.css';

const ListTestimonials = ({people}) => {
    return(
        <div className="list-test-cont container">
            {people.map((person) => {
                const {id, testimonials, name, image} = person;
            return <div className="list-test" key={id}>
                <img src={image} alt={name}/>
                <p>{testimonials}</p>
                <h3>{name}</h3>
            </div>
            })}
        </div>
    )
}

export default ListTestimonials;