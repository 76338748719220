import React from 'react'
//Style CSS
import './ContactSection.css';

const ContactSection = () => {
    return (
        <div className="contact-cont">
            <div className="container contact-flex">
                <div className="contact-box">
                    <p>Agnes Deniega</p>
                    <p><span>General Manager</span></p>
                    <p>+63 936 242 7601</p>
                    <p>franchise@ltgcreditline.com</p>
                    <p>franchise@mamsltg.com</p>
                </div>
                <div className="contact-box">
                    <p>Cathy Car Oxales</p>
                    <p><span>HR Head</span></p>
                    <p>+63 965 098 5100</p>
                    <p>ltgcreditline.hr@gmail.com</p>
                </div>
                <div className="contact-box">
                    <p>Contact Details:</p>
                    <p>GLOBE: +63 995 104 6267</p>
                    <p>SMART: +63 960 867 0566</p>
                </div>
            </div>
    </div>
    )
}

export default ContactSection;