import React from 'react';
//Style CSS
import './AboutUsStyle.css';
//Image
import Blessed1 from '../../img/blessed-1.jpg'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const BlessedSection = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="blessed-container container">
            <div className="blessed-content">
                <h2 className="blessed-title m5-title">Blessed</h2>
                <p className="justify-p">Soliman did not even go to fancy schools even if his parents can well afford to send him to Manila to study. Instead, he stayed in his hometown and finished studies at Bicol University. He also studied law at the Aquinas University, also in his hometown.</p>
                <p className="justify-p">He was not an honor student but he finished college when he was only 19 years old. His first job was as PLDT customer service representative with a P4,000 monthly salary that could barely support his needs. But he learned a lot about how to deal with customers after two years of listening to all the complaints of customers, whose telephone application, at that time, had to wait 2 years before they can get a phone line.</p>
                <p className="justify-p">He left that job because he was envious at those phone applicants, who are getting line for their business. He also noted that those retiring from his company can only afford to buy their first car, a second hand unit at that. There was no way he would end up like these retirees. That started his entrepreneurial endeavor.</p>
                <p className="justify-p">At first, he went into motorcycle parts in 1997 in Daraga. He entered into the insurance industry where he was doing well. Then he ventured into other business such as gun club, security agency, lending and then trucking.</p>
                <p className="justify-p">Looking back, Soliman did not see his micro lending franchise to fly this way. “To be very honest when we started last year it was totally unknown to me, but I was optimistic,” says Soliman. In fact, Francorp had very conservative targets, but they got 50 applicants on their first year and they can only open three branches a month.</p>
                <p className="justify-p">Now, the 44-year-old businessman with four kids, 2 girls and twin sons, has more than enough. But Soliman said he still remains the same, his lifestyle has remained unchanged.</p>
                <p className="justify-p">“If you believe a business can help others, persevere. Once I’ve decided to pursue a business, you can never stop me. I will see it through the rain,” says Soliman, who looks up to Jack Ma, Elon Musk, Injap Sia as his business idols.</p>
                <p className="justify-p">Most of all, Soliman said he always prays at night and goes to church because “I am really blessed, and I also promised to help others. So, I ask God not to give up on me because I am not doing this for myself alone but for others.”</p>
            </div>
            <div className="blessed-img">
                <img src={Blessed1} alt="blessed img" />
            </div>
        </motion.div>
    )
}

export default BlessedSection;