import React from 'react';
//Sidebar Component
import FrSidebar from '../FranchiseProgComponents/FrSidebar.js'
//Style CSS
import './FrProg.css';
//Images
import OpMain from '../../img/santa-maria-12.jpg';
import Opening1 from '../../img/opening-1.jpg';
import Opening2 from '../../img/opening-2.jpg';
import Opening3 from '../../img/opening-3.jpg';
import Opening4 from '../../img/opening-4.jpg';

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'

import { Link } from "react-router-dom";

const Opening = () => {
    return(
    <div className="container frprog-cont">
        <FrSidebar/>
        <div className="frprog-cont-info">
            <motion.div
                variants={fadeAnimation}
                initial='hidden'
                animate='show'
                exit='exit'   
            >
                <img className="frprog-img" src={OpMain} alt="main" />
                <h2 className="frprog-title">OPENING SUPPORT</h2>
                <div className="frprog-cont-box">
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">DETAILED AREA MAPPING</h3>
                        <img className="frprog-img" src={Opening1} alt="detailed area mapping" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">GUIDED BUSINESS ROLL OUT</h3>
                        <img className="frprog-img" src={Opening2} alt="guided business roll out" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">SYSTEMS INSTALLATION AND ORIENTATION</h3>
                        <img className="frprog-img" src={Opening3} alt="systems installation and orientation" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">BRANCH BLESSING ON OPENING DAY</h3>
                        <img className="frprog-img" src={Opening4} alt="branch blessing on opening day" />
                    </div>
                </div>
            </motion.div>
            <div className="left-right-btn">
                <div className="next-post-btn">
                    <Link to="/preopening"><HiOutlineArrowNarrowLeft/> Prev</Link>
                </div>
                <div className="next-post-btn">
                    <Link to="/continuing-support">Next <HiOutlineArrowNarrowRight/></Link>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Opening;