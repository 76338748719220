import React from 'react';
import FormSection from '../components/ContactComponents/FormSection.js';
import ContactSection from '../components/ContactComponents/ContactSection.js';
import ContactMap from '../components/ContactComponents/ContactMap.js';

import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animation';

const ContactUs = ({service, template, user}) => {
    return (
        <motion.div
            variants={pageAnimation}
            initial='hidden'
            animate='show'
            exit='exit'
        >
            <FormSection 
                service={service}
                template={template}
                user={user}
            />
            <ContactSection/>
            <ContactMap/>
        </motion.div>
    )
}

export default ContactUs;