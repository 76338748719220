import React from 'react';
//Style CSS
import './ContactMap.css'

const ContactMap = () => {
    return (
        <div className="map-cont">
            <div className="map-box">
                <h5>Main Office</h5>
                <iframe className="contact-map" title="contact-map-1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13068.371821426235!2d123.7376615931166!3d13.146612524327871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a101a5a6ae08d1%3A0x85a42d9fbe4e02c7!2sLT%26G+Credit+Line!5e0!3m2!1sen!2sph!4v1558952936010!5m2!1sen!2sph" allowFullScreen></iframe>
            </div>
            <div className="map-box">
                <h5>Manila Office</h5>
                <iframe className="contact-map" title="contact-map-2" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6494.006610303498!2d121.03277379005638!3d14.578583364233793!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c84b35379115%3A0xa15b460d0bbe4308!2s315%20Maysilo%20Cir%2C%20Mandaluyong%2C%201550%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1628843767898!5m2!1sen!2sph"  allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default ContactMap;