import React from 'react'
//Sidebar Component
import FrSidebar from '../FranchiseProgComponents/FrSidebar.js';
//Style CSS
import './FrProg.css';
import './FranchiseTraining.css';
//Image
import FranchiseTrainingMain from '../../img/franchise-training-main.jpg';

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'

import { Link } from "react-router-dom";

const FranchiseTraining = () => {
    return (
        <div className="container frprog-cont">
            <FrSidebar />
            <div className="frprog-cont-info">
                <motion.div
                    variants={fadeAnimation}
                    initial='hidden'
                    animate='show'
                    exit='exit'
                >
                    <img className="frprog-img" src={FranchiseTrainingMain} alt="main" />
                    <h2 className="frprog-title">FRANCHISE TRAINING</h2>
                    <div className="frprog-franchise-box">
                        <h4 className="frprog-h4">5-day Classroom Training</h4>
                        <p>A Total of <span>15 Days Comprehensive Training</span> at our facility.</p>
                    </div>
                    <div className="frprog-box-list">
                        <ul>
                            <li>Company Orientation</li>
                            <li>Product Orientation</li>
                            <li>Staff Orientation</li>
                            <li>CA Training, Duties & Responsibilities</li>
                            <li>Survey, Mapping, Sales & Collection Procedures</li>
                            <li>Systems Overview</li>
                            <li>Notes & Collection Report</li>
                            <li>Cash Flow</li>
                            <li>Personal Finance Awareness</li>
                            <li>Cash Flow Proper</li>
                            <li>Attitude, Belief & Commitment Rules</li>
                            <li>Games</li>
                            <li>Processing with Franchise Owners</li>
                            <li>Admin, Branch Cashier, Customer Care Specialist, Branch Manager, and Credit Investigator Orientation</li>
                            <li>Notes & Collection Report Mastery</li>
                            <li>Money Counterfeit Detection</li>
                            <li>Best Practices</li>
                            <li>Delinquency Management</li>
                            <li>Revalida and Graduation</li>
                            <li>10 Days Actual Field Coaching</li>
                            <li>3 days On-site Opening Training with the Area Supervisors</li>
                        </ul>
                    </div>
                </motion.div>
                <div className="left-right-btn">
                    <div className="next-post-btn">
                        <Link to="/marketing-support"><HiOutlineArrowNarrowLeft /> Prev</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FranchiseTraining;