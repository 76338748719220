import React from 'react';
import MapSection from '../components/BranchesComponents/MapSection.js';
import ListBranch from '../components/BranchesComponents/ListBranch.js';

import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animation';

const Branches = ({branches}) => {
  return(
    <>
      <motion.div
        variants={pageAnimation}
        initial='hidden'
        animate='show'
        exit='exit'
      >
        <MapSection/>
        <ListBranch branches={branches}/>
      </motion.div>
    </>
  )
}

export default Branches;