import React from 'react';
//Style CSS
import './Model.css';
//Framer Motion - animation
import {motion} from 'framer-motion';
//Carousel react-bootstrap
import {Carousel} from 'react-bootstrap';

const Model = ({branch_name, hide, address, embed_map, listImages, contact_no, email}) => {
        /* const [selectedImg, setSelectedImg] = useState(listImages[0]) */

        let modelStyle = {
            display: 'block',
            backgroundColor: 'rgba(0,0,0,0.8)'
        }
        return(
            <>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3
                        }
                    }}
                    className="modal show fade" 
                    style={modelStyle}
                >
                    <motion.div 
                        initial={{
                                y: -200,
                                opacity: 0
                            }}
                            animate={{
                                y: 0,
                                opacity: 1,
                                transition: {
                                ease: "easeOut",
                                duration: 0.3,
                                }
                            }}
                        className="modal-dialog modal-xl modal-dialog-scrollable"
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="m-0 modal-title">LT&G Credit Line {branch_name} Branch</h3>
                                <button type="button" className="btn-close" onClick={hide}></button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-info-cont">
                                    <div className="modal-divider">
                                            <p className="address-m"><span>Branch address:</span></p>
                                            <p className="address-data">{address}</p>
                                            <p className="contact-m"><span>Contact Number:</span></p>
                                            {contact_no.map((contact) =>
                                                <p className="contact">{contact}</p>
                                            )}
                                            <p className="email-m"><span>Email address:</span></p> 
                                            {email.map((mail) => 
                                                <p className="email">{mail}</p> 
                                            )}
                                    </div>
                                    <div className="mapouter">
                                        <div className="gmap_canvas">
                                            <iframe className="iframe-map" title={branch_name} width="30%" height="250px" id="gmap_canvas" src={embed_map} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="img-modal-cont">

                                {/* <img className="main-img" src={selectedImg} alt={selectedImg} /> 
                                    <div className="cont-img">
                                        {listImages.map((img, index) =>
                                            <div key={index} className="sub-cont-img">
                                                <img className="sub-img"
                                                    style={{ border: setSelectedImg === img ? "1px solid red": "" }} 
                                                    src={img} 
                                                    alt={img} 
                                                    onClick={() => setSelectedImg(img)}
                                                />
                                            </div>
                                        )}
                                    </div> */}

                                    <Carousel fade>
                                        {listImages.map((img, index) =>
                                            <Carousel.Item key={index} interval={3000}>
                                                <img
                                                className="d-block w-100 img-w-100"
                                                src={img}
                                                alt={img}
                                                />
                                            </Carousel.Item>
                                        )}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </>
        )
    }


export default Model;