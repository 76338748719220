import React, { useState } from 'react';
//Style CSS
import './ListBranch.css';
//import Model - Modal Component
import Model from '../BranchesComponents/Model.js';

function ListBranch({branches}) {
    const [model, setModel] = useState(false);
    const [tempdata, setTempdata] = useState([]);

    const getData = (branch_name, image, address, embed_map, listImages, contact_no, email) => {
        let tempData = [branch_name, image, address, embed_map, listImages, contact_no, email];
        setTempdata(() => [1, ...tempData]);

        return setModel(true);
    }

    return (
        <div className="branch">
            <div className="container">
                <div className="branch-cont">
                    {branches.map((branch) => {
                        const {id, branch_name, image, address, embed_map, listImages, contact_no, email} = branch;
                    return ( 
                        <button onClick={() => getData(branch_name, image, address, embed_map, listImages, contact_no, email)} key={id}>
                        <img src={image} alt={branch_name} />
                    </button>
                    )})}
                </div>
            </div>
            {
                model === true ? <Model branch_name={tempdata[1]} image={tempdata[2]} address={tempdata[3]} embed_map={tempdata[4]} listImages={tempdata[5]} contact_no={tempdata[6]} email={tempdata[7]} hide={() => setModel(false)}/>: ''
            }
        </div>
    )
}

export default ListBranch
