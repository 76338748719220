import React from 'react';
//Image
import Francorp from '../../img/francorp-logo.png';
import Logo from '../../img/logo1.png';
//Style CSS
import './FranchiseSection.css';
import './AboutUsStyle.css';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const FranchiseSection = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="franchise-container container">
            <div className="franchise-content">
                <h2 className="franchise-title m5-title">Franchise</h2>
                <p className="justify-p">LT&G was doing pretty well that after establishing 5 branches in Bicol, Soliman was looking to further expand the business to cover more territories outside of Bicol. To do that, he thought of going into franchising, but everybody was negative about saying there is no such thing as franchising concept in micro lending.</p>
                <p className="justify-p">Two years ago, he met with Bing Limjoco, CEO of Francorp Philippines, the country’s authority in franchising. Limjoco, the mother of Philippine franchising, was quick to digest the intricacies of micro lending. She declared this sector highly franchisable. Thus, the crafting of the country’s first franchise concept in micro lending under Soliman’s MAMS LTG Franchising Corp.</p>
                <p className="justify-p">LT & G’s overall investment package is P3.5 million, but majority of that will be allotted for lending. The franchise fee is only P448,000. The franchise was rolled in 2018. Last year, they opened 30 branches and more than 50 branches are coming by end this year.
                The first thing that they did was strengthened its IT system, which is the heart of their operations. This is important as all processes are computerized and all borrowings and payments are custom to fit to the requirements of each customer.</p>
                <p className="justify-p">“The heart of our operation is our proprietary sales and collection system that we built. So, we have our own IT department,” he adds.
                “We are offering a turnkey package, set up the office and training of the people,” says Soliman.
                “Our clients and us are partners in growing our business,” says Soliman as he cited the number of borrowers that they have helped in Bicol even before going into franchising. So far, LT & G is the country’s fastest growing micro lending enterprise.</p>
                <p className="justify-p">Aside from the 30 branches that they opened last year, they are looking at over 50 plus to open this year alone. Soliman also aims to open additional company-owned branches to a total of 8-10.</p>
                <p className="justify-p">Without mentioning the exact figure, Soliman said the amount for lending has also grown exponentially.</p>
            </div>
            <div className="franchise-img">
                <img className="franchise-corp-img" src={Francorp} alt="francrop logo" />
                <img className="franchise-logo-img" src={Logo} alt="lt&g logo" />
            </div>
        </motion.div>
    )
}

export default FranchiseSection;