import FranchiseExpoIndo from "../img/franchise-expo.jpg"
import Indo1 from '../img/expoindo-1.jpg';
import Indo2 from '../img/expoindo-2.jpg';
import Indo3 from '../img/expoindo-3.jpg';
import Indo4 from '../img/expoindo-4.jpg';
import Indo5 from '../img/expoindo-5.jpg';
import Indo6 from '../img/expoindo-6.jpg';
import Indo7 from '../img/expoindo-7.jpg';
import Indo8 from '../img/expoindo-8.jpg';
import Indo9 from '../img/expoindo-9.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: FranchiseExpoIndo
    },
    {
        id: 2,
        image: Indo1
    },
    {
        id: 3,
        image: Indo2
    },
    {
        id: 4,
        image: Indo3
    },
    {
        id: 5,
        image: Indo4
    },
    {
        id: 6,
        image: Indo5
    },
    {
        id: 7,
        image: Indo6
    },
    {
        id: 8,
        image: Indo7
    },
    {
        id: 9,
        image: Indo8
    },
    {
        id: 10,
        image: Indo9
    }
];