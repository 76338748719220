import React from "react";
//React Router-Dom
import { Link } from "react-router-dom";
//Style CSS
import './NewsAndEvents.css'
//Images
import Boss from "../../img/microlend-boss.jpg"
import Team from "../../img/teambuilding/team9.JPG"
import SanRafael from "../../img/san-rafael.jpg"
import SanMaria from "../../img/santa-maria-14.jpg"
import FranchiseExpoIndo from "../../img/franchise-expo.jpg"
import ExpoCebu from "../../img/cebu-expo.jpg"
import MeetPH from '../../img/meet-ph.jpg'
import FranchiseAsia from '../../img/franchise-asia-ph.jpg'
import AmazingRace from '../..//img/amazing-race.jpg'
//React-Icon
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
//Component
import SocialMediaComponents from "./SocialMediaComponents";

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

const MeetPHComponents = () => {
    return(
        <div className="container nae-flex">
            <div className="nae-content-head">
                <motion.div 
                    variants={fadeAnimation}
                    initial='hidden'
                    animate='show'
                    exit='exit'  
                >
                    <div className="news-and-event-head">
                        <img src={MeetPH} alt="meet ph" />
                        <h4>MARCO ANTONIO M. SOLIMAN</h4>
                        <p>CEO and President</p>
                        <p>LT&G Credit Line Corp.</p>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Meet PH’s first micro credit financing on franchise</h2>
                        <p className="pub-margin">Published from <Link to="/">Manila Bulletin</Link></p>
                        <p><span>by Bernie Cahiles-Magkilat</span></p>
                        <div>
                            <p>Micro lending in this country is often associated with five-six practices by loan sharks, who charge very high interest rates. Instead of helping those in need, they buried these poor borrowers in deeper debts for a long time. But the unbanked Filipinos continue to patronize these micro lenders because it is easy, quick and informal.</p>
                            <p>Based in Legazpi, Albay, entrepreneur <span style={{fontWeight: 'bold'}}>Marco Antonio M. Soliman</span> has joined the fray by creating a corporation LT & G Credit Line Corp. to operate a lending company.</p>
                            <p>With his mantra: “Better Loans. Better Lives,” Soliman seeks to fill the capital requirement needs of micro enterprises at very affordable rates. Soliman also defied all odds to become the country’s first micro lending being offered on franchise.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>The Company</h2>
                        <div>
                            <p>Soliman, who came from a fairly well-to-do family in Bicol put up his first micro lending branch in Legazpi in 2014. With an initial capitalization of P1 million, he operates the micro lending enterprise with wife Gloria and three collectors.</p>
                            <p>According to Soliman, the need for micro financing is huge because in Bicol alone there are around 40 lending companies catering to different markets such as teachers and pensioners. But LT & G chose to serve the micro enterprises because they feel they need to intervene, this sector already being held captive by loan sharks.</p>
                            <p>Their borrowers are really very micro or backyard enterprises with minimum loans of P5,000 and maximum of P50,000 or an average of P15,000 to P20,000.</p>
                            <p>Their clients include small sari-sari stores, market vendors, carinderia, vulcanizing shops, photo copiers, salons, small bakeries, hardware stores, meatshops, and fruit shops.</p>
                            <p>“This is the unbanked sector and very vulnerable to loan sharks. Without us, they go to these loan sharks. It’s sad as they fall into heavy debt because most of their earnings just go to pay the interest. So, they remain poor. In our case, we want them to be our partners for growth and we are very passionate about this mission,” he adds.</p>
                            <p>LT & G offers an interest rate that is way below the 20 percent interest rate being peddled by five-six loan sharks. They do not give their clients a hard time. The only requirement is a barangay permit and credit investigation after which the loans are released in 2-3 days.</p>
                            <p>“We have to validate that they indeed have an existing business,” he adds. Micro lending is not easy. Soliman said there is a science in doing this business.</p>
                            <p>“You have to study how to collect the money on a daily basis. We have a system to validate, we have cash flow analysis. We teach our clients to validate how much to allow a borrower so that it is within his paying capacity. If the validation is wrong, the problem will immediately show, so we need to establish the cash flow every day. So, there is science, and the IT system is the heart of our operation,” he adds.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Franchise</h2>
                        <div>
                            <p>LT & G was doing pretty well that after establishing 5 branches in Bicol, Soliman was looking to further expand the business to cover more territories outside of Bicol. To do that, he thought of going into franchising, but everybody was negative about saying there is no such thing as franchising concept in micro lending.</p>
                            <p>Two years ago, he met with Bing Limjoco, CEO of Francorp Philippines, the country’s authority in franchising. Limjoco, the mother of Philippine franchising, was quick to digest the intricacies of micro lending. She declared this sector highly franchisable. Thus, the crafting of the country’s first franchise concept in micro lending under Soliman’s MAMS LTG Franchising Corp.</p>
                            <p>LT & G’s overall investment package is P3.5 million, but majority of that will be allotted for lending. The franchise fee is only P448,000. The franchise was rolled in 2018. Last year, they opened 30 branches and more than 50 branches are coming by end this year.</p>
                            <p>The first thing that they did was strengthened its IT system, which is the heart of their operations. This is important as all processes are computerized and all borrowings and payments are custom to fit to the requirements of each customer.</p>
                            <p>“The heart of our operation is our proprietary sales and collection system that we built. So, we have our own IT department,” he adds.</p>
                            <p>“We are offering a turnkey package, set up the office and training of the people,” says Soliman.</p>
                            <p>“Our clients and us are partners in growing our business,” says Soliman as he cited the number of borrowers that they have helped in Bicol even before going into franchising. So far, LT & G is the country’s fastest growing micro lending enterprise.</p>
                            <p>Aside from the 30 branches that they opened last year, they are looking at over 50 plus to open this year alone. Soliman also aims to open additional company-owned branches to a total of 8-10.</p>
                            <p>Without mentioning the exact figure, Soliman said the amount for lending has also grown exponentially.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Criteria</h2>
                        <div>
                            <p>For its franchising business, Soliman said they chose partners whose goals are aligned with them.</p>
                            <p>“We look for willingness to be a part of an organization which primary goal is to be of help to clients because that ensures our growth,” says Soliman. He shuns shortsighted entrepreneurs who are looking for quick returns. They believe that their clients must grow with them so that if they need more capital, they will still tap LT & G.</p>
                            <p>They have also instituted rigid daily meetings to thoroughly monitor clients, how are they performing in business and who are behind in their payments.
                            They also put up a customer care specialist unit to take care of all clients. For instance, they greet their clients on their birthdays with simple birthdays cards.</p>
                            <p>“This a very simple gesture but this is one way to keep them loyal to us as we put a premium in establishing good relations with them because it would be very easy for them to go to other lending companies or priority other lenders for their payments,” he adds.</p>
                            <p>Every month they also conduct raffle prizes for rice and grocery items. This is to establish good relations and to instill its goal that LT & G is not just about lending but giving back because if not for them there is no LT & G also.</p>
                            <p>“So, we also find the right balance of being of help to clients,” he adds noting how very affordable their rates are.</p>
                            <p>But because of their good relations with their clients they have conscientious payors. In fact, they have over 120 percent overall collection performance. Also, loans are being paid in full in 70-80 days instead of 100 days.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Success</h2>
                        <div>
                            <p>The win-win formula has resulted in success for both the lender and borrower.</p>
                            <p>Most of their clients have already stopped borrowing from them because they have sufficient capital already. Others still continue to borrow from them for their expansion like a sari-sari store has grown to a mini-grocery and needs additional capital. Others need new freezers and new equipment to support their expansion.</p>
                            <p>Because of the good business, 80 percent of its franchises have 2-3 multiple branches.</p>
                            <p>Soliman himself has been so passionate and focus. When he thinks of something, he won’t stop until he achieves his goal. He would send messages to his people even in the wee hours of the night for fear he might forget them after.</p>
                            <p>His people undergo intensive training. The collectors or the credit analysts must undergo a rigid 21-day training including actual exposure in the field. He prefers to accept collectors with no experience.</p>
                            <p>“I want my team members onboard because I need their support,” he adds.</p>
                            <p>Soliman knows that 90 percent of entrepreneurs gave up on the first set of trials. But, he said, they did not think that trials normally come during the first phase of a project. Once that is hurdled, the next phase would be easier.</p>
                            <p>“It is about your goals and putting the necessary hardwork to achieve them. Never surrender because trials will always be there, you will encounter difficulties but never give up,” he advises young entrepreneurs.</p>
                            <p>For instance, the ultimate challenge of a lending company is retention of people. He solved this problem by raising the salaries of people and giving them fringe benefits, incentives and monthly rice allowance. Their collectors are provided with motorcycles. They hold team building activities for his 100 people. The entire network, including the franchises, has 400.</p>
                            <p>“If you are good to your people and they are happy, this would be evident in their performance. You might think you have earned more from scrimping on the benefits for employees you are wrong because if you equate that to income, it could have been higher had you given more to your people,” says Soliman.</p>
                            <p>His people, particularly the core team has a bigger voice and he listens to them and if he likes the idea, he would immediately allow its implementation. LT & G can act and adjust quickly because they are small and agile. Now, they are putting up an office in Manila because they already have 20 branches here.</p>
                            <p>“I let my people shine and if I hire someone, he has to fill a specific need of the company. So, I always encourage them,” says Soliman, who sometimes runs his company remotely.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Success</h2>
                        <div>
                            <p>The win-win formula has resulted in success for both the lender and borrower.</p>
                            <p>Most of their clients have already stopped borrowing from them because they have sufficient capital already. Others still continue to borrow from them for their expansion like a sari-sari store has grown to a mini-grocery and needs additional capital. Others need new freezers and new equipment to support their expansion.</p>
                            <p>Because of the good business, 80 percent of its franchises have 2-3 multiple branches.</p>
                            <p>Soliman himself has been so passionate and focus. When he thinks of something, he won’t stop until he achieves his goal. He would send messages to his people even in the wee hours of the night for fear he might forget them after.</p>
                            <p>His people undergo intensive training. The collectors or the credit analysts must undergo a rigid 21-day training including actual exposure in the field. He prefers to accept collectors with no experience.</p>
                            <p>“I want my team members onboard because I need their support,” he adds.</p>
                            <p>Soliman knows that 90 percent of entrepreneurs gave up on the first set of trials. But, he said, they did not think that trials normally come during the first phase of a project. Once that is hurdled, the next phase would be easier.</p>
                            <p>“It is about your goals and putting the necessary hardwork to achieve them. Never surrender because trials will always be there, you will encounter difficulties but never give up,” he advises young entrepreneurs.</p>
                            <p>For instance, the ultimate challenge of a lending company is retention of people. He solved this problem by raising the salaries of people and giving them fringe benefits, incentives and monthly rice allowance. Their collectors are provided with motorcycles. They hold team building activities for his 100 people. The entire network, including the franchises, has 400.</p>
                            <p>“If you are good to your people and they are happy, this would be evident in their performance. You might think you have earned more from scrimping on the benefits for employees you are wrong because if you equate that to income, it could have been higher had you given more to your people,” says Soliman.</p>
                            <p>His people, particularly the core team has a bigger voice and he listens to them and if he likes the idea, he would immediately allow its implementation. LT & G can act and adjust quickly because they are small and agile. Now, they are putting up an office in Manila because they already have 20 branches here.</p>
                            <p>“I let my people shine and if I hire someone, he has to fill a specific need of the company. So, I always encourage them,” says Soliman, who sometimes runs his company remotely.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Growth</h2>
                        <div>
                            <p>Soliman said there is still more room for micro-lending expansion. Its study points to a potential 250-300 branches nationwide in the next five years. But they are already looking at conquering other territories in Southeast Asia, particularly Cambodia, Myanmar, Jakarta, two years from now. They also see potential in Africa and Latin America.</p>
                            <p>“These are countries with similar dynamics as the Philippines,” says Soliman.
                            The potential is huge. They could also offer other related services like bills payments and partnerships with remittance centers because they have a wide network all over the country. This would open opportunity for rural banking, as well.</p>
                            <p>“We will always evolve according to the needs of the market,” he adds.
                            Despite having put up a good system enough to attract a lot of franchise applicants and ensure continued growth, Soliman said, “We are still a work in progress. There is still a lot of room for improvement. I know that for us to achieve our goals, we have to ensure that our franchisees are happy because if they are happy it will translate to better earnings.”</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Blessed</h2>
                        <div>
                            <p>Soliman did not even go to fancy schools even if his parents can well afford to send him to Manila to study. Instead, he stayed in his hometown and finished studies at Bicol University. He also studied law at the Aquinas University, also in his hometown.</p>
                            <p>He was not an honor student but he finished college when he was only 19 years old. His first job was as PLDT customer service representative with a P4,000 monthly salary that could barely support his needs. But he learned a lot about how to deal with customers after two years of listening to all the complaints of customers, whose telephone application, at that time, had to wait 2 years before they can get a phone line.</p>
                            <p>He left that job because he was envious at those phone applicants, who are getting line for their business. He also noted that those retiring from his company can only afford to buy their first car, a second hand unit at that. There was no way he would end up like these retirees. That started his entrepreneurial endeavor.</p>
                            <p>At first, he went into motorcycle parts in 1997 in Daraga. He entered into the insurance industry where he was doing well. Then he ventured into other business such as gun club, security agency, lending and then trucking.</p>
                            <p>Looking back, Soliman did not see his micro lending franchise to fly this way. “To be very honest when we started last year it was totally unknown to me, but I was optimistic,” says Soliman. In fact, Francorp had very conservative targets, but they got 50 applicants on their first year and they can only open three branches a month.</p>
                            <p>Now, the 44-year-old businessman with four kids, 2 girls and twin sons, has more than enough. But Soliman said he still remains the same, his lifestyle has remained unchanged.</p>
                            <p>“If you believe a business can help others, persevere. Once I’ve decided to pursue a business, you can never stop me. I will see it through the rain,” says Soliman, who looks up to Jack Ma, Elon Musk, Injap Sia as his business idols.</p>
                            <p>Most of all, Soliman said he always prays at night and goes to church because “I am really blessed, and I also promised to help others. So, I ask God not to give up on me because I am not doing this for myself alone but for others.”</p>
                        </div>
                    </div>
                    <SocialMediaComponents/>
                    
                </motion.div>
                <div className="left-right-btn">
                    <div className="next-post-btn">
                        <Link to="/expocebu"><HiOutlineArrowNarrowLeft/> Prev Post</Link>
                    </div>
                    <div className="next-post-btn">
                        <Link to="/amazingrace">Next Post <HiOutlineArrowNarrowRight/></Link>
                    </div>
                </div>
            </div>
            
            <div className="other-post-cont">
                <h3>Other Posts</h3>
                <div className="other-post-subcont">
                    <div className="other-post-box">
                        <img src={Team} alt="team-building" />
                        <div>
                            <Link to="/team-building">Summer Outing and Team Building</Link>
                            <p>June 4 - 5, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={SanRafael} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-sanrafael-branch">Newly Opened San Rafael, Bulacan Branch</Link>
                            <p>March 4, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={SanMaria} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-santamaria-branch">Newly Opened Santa Maria, Bulacan Branch</Link>
                            <p>December 4, 2021</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={Boss} alt="micro-lending" />
                        <div>
                            <Link to="/microlend">Micro-lending firm gains ground by franchising</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseExpoIndo} alt="franchise expo indo" />
                        <div>
                            <Link to="/expoindo">Franchise Expo, Indonesia 2019</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={ExpoCebu} alt="franchise expo cebu" />
                        <div>
                            <Link to="/expocebu">Franchise Expo, Cebu City 2019</Link>
                            <p>June 22, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box select-active">
                        <img src={MeetPH} alt="meet ph" />
                        <div>
                            <Link to="/meetph">Meet PH’s first Micro Credit Financing on Franchise</Link>
                            <p>May 21, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={AmazingRace} alt="amazing race" />
                        <div>
                            <Link to="/amazingrace">AMAZING RACE 2019</Link>
                            <p>May 11, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseAsia} alt="franchise asia" />
                        <div>
                            <Link to="/franchiseasia">Franchise Asia Philippines 2019</Link>
                            <p>April 14, 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetPHComponents;