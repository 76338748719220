import React from 'react';
//Style CSS
import './TeamMain.css';
//Images
import Marlon from '../../img/Team-marlon1.jpg';
import Julius from '../../img/team-yuz1.jpg';
import mj from '../../img/team-mj.jpg';
import al from '../../img/team-al1.jpg';
import Blank from '../../img/team-blank.jpg'


import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const ITDept = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="container main-team">
            <h2 className="main-team-title-2">Information Technology Department</h2>
            <div className="team-box-2">
                <div className="team-1-cont">
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Marlon} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Marlon Thomas Marcellana</h3>
                            <span className="team-post">IT Department Head</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Julius} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Julius Manipol</h3>
                            <span className="team-post">Sr. Database Admin</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={al} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Alfred Petilla</h3>
                            <span className="team-post">Technical & Database Admin</span>
                        </div>
                    </div>
                </div>
                <div className="team-2-cont">
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={mj} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Mark John Presco</h3>
                            <span className="team-post">Web Developer & Technical </span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">...</h3>
                            <span className="team-post">...</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">...</h3>
                            <span className="team-post">...</span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default ITDept