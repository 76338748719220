import React from 'react';
//Styles CSS
import './AboutUsStyle.css';
//Images
import Growth1 from '../../img/growth-1.jpg'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const GrowthSection = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="growth-container container">
            <div className="growth-img">
                <img src={Growth1} alt="growth img" />
            </div>
            <div className="growth-content">
                <h2 className="growth-title m5-title">Growth</h2>
                <p className="justify-p">Soliman said there is still more room for micro-lending expansion. Its study points to a potential 250-300 branches nationwide in the next five years. But they are already looking at conquering other territories in Southeast Asia, particularly Cambodia, Myanmar, Jakarta, two years from now. They also see potential in Africa and Latin America.</p>
                <p className="justify-p">“These are countries with similar dynamics as the Philippines,” says Soliman.
                The potential is huge. They could also offer other related services like bills payments and partnerships with remittance centers because they have a wide network all over the country. This would open opportunity for rural banking, as well.</p>
                <p className="justify-p">“We will always evolve according to the needs of the market,” he adds.
                Despite having put up a good system enough to attract a lot of franchise applicants and ensure continued growth, Soliman said, “We are still a work in progress. There is still a lot of room for improvement. I know that for us to achieve our goals, we have to ensure that our franchisees are happy because if they are happy it will translate to better earnings.”</p>
            </div>
        </motion.div>
    )
}

export default GrowthSection;