import React, {useState} from 'react';
/* style */
import './App.css';
import './components/GlobalStyles.css';
/* Router */
import {Route, Switch, useLocation } from 'react-router-dom';
/* Scroll to top when clicking other page link */
import ScrollToTop from './components/ScrollToTop';
/* testimonial data */
import data from './data/datatest.js';
import datateam from './data/datateam.js';
import databranch from './data/databranch.js';
import datasanrafa from './data/datasanrafa.js';
import datasanmaria from './data/datasanmaria.js';
import dataexpoindo from './data/dataexpoindo.js';
import dataexpocebu from './data/dataexpocebu.js';
import dataamaze from './data/dataamaze.js';
import franchiseasia from './data/datafranchiseasia.js';
/* pages */
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Navbar from './components/NavCont';
import Footer from './components/Footer';
import SecondFooter from './components/SecondFooter';
import Testimonials from './pages/Testimonials';
import Branches from './pages/Branches';
import Franchising from './pages/Franchising';
import ContactUs from './pages/ContactUs';
import NoMatch from './pages/NoMatch';
/*Franchise Program Pages */
import PreOpening from './components/FranchiseProgComponents/PreOpening';
import Opening from './components/FranchiseProgComponents/Opening';
/*Top scroll button*/
import TopScroll from './components/ScrollComponents/TopScroll'
/*Team Pages*/
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ContinuingSupport from './components/FranchiseProgComponents/ContinuingSupport';
import MarketingSupport from './components/FranchiseProgComponents/MarketingSupport';
import FranchiseTraining from './components/FranchiseProgComponents/FranchiseTraining';
import Team from './pages/Team';
/*News and Events*/
import NewOpSanRafaelComponents from './components/NewsAndEventsComponents/NewOpSanRafaelComponents';
import NewOpSantaMariaComponents from './components/NewsAndEventsComponents/NewOpSantaMariaComponents';
import MicroLendComponents from './components/NewsAndEventsComponents/MicroLendComponents';
import ExpoIndoComponents from './components/NewsAndEventsComponents/ExpoIndoComponents';
import ExpoCebuComponents from './components/NewsAndEventsComponents/ExpoCebuComponents';
import MeetPHComponents from './components/NewsAndEventsComponents/MeetPHComponents';
import AmazingRaceComponents from './components/NewsAndEventsComponents/AmazingRaceComponents';
import FranchiseAsiaComponents from './components/NewsAndEventsComponents/FranchiseAsiaComponents';
//Framer Motion
import { AnimatePresence } from 'framer-motion';
import TeamBuilding from './components/NewsAndEventsComponents/TeamBuilding';

function App() {
  const [people] = useState(data);
  const [branches] = useState(databranch);
  const [teams] = useState(datateam)
  const [sanrafaels] = useState(datasanrafa);
  const [sanmarias] = useState(datasanmaria);
  const [indos] = useState(dataexpoindo);
  const [cebus] = useState(dataexpocebu);
  const [amazes] = useState(dataamaze);
  const [asias] = useState(franchiseasia);

  const {REACT_APP_PAGE_ID_KEY, REACT_APP_ID_KEY, REACT_APP_SERVICE_KEY, REACT_APP_TEMPLATE_KEY, REACT_APP_USER_KEY} = process.env 

  const location = useLocation();

  return (
    <>
      <ScrollToTop/>
      <Navbar/>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route exact path="/about">
              <AboutUs/>
            </Route>
            <Route exact path="/testimonials">
              <Testimonials people={people} />
            </Route>
            <Route exact path="/branches">
              <Branches 
                branches={branches} 
              />
            </Route>
            <Route exact path="/franchising">
              <Franchising />
            </Route>
            <Route exact path="/contact">
              <ContactUs 
                service={REACT_APP_SERVICE_KEY}
                template={REACT_APP_TEMPLATE_KEY}
                user={REACT_APP_USER_KEY}
              />
            </Route>
            <Route exact path="/preopening">
              <PreOpening />
            </Route>
            <Route exact path="/opening">
              <Opening />
            </Route>
            <Route exact path="/continuing-support">
              <ContinuingSupport />
            </Route>
            <Route exact path="/marketing-support">
              <MarketingSupport />
            </Route>
            <Route exact path="/franchise-training">
              <FranchiseTraining />
            </Route>
            <Route exact path="/team">
              <Team />
            </Route>
            <Route exact path="/team-building">
              <TeamBuilding teams={teams} />
            </Route>
            <Route exact path="/newlyopened-sanrafael-branch">
              <NewOpSanRafaelComponents sanrafaels={sanrafaels} />
            </Route>
            <Route exact path="/newlyopened-santamaria-branch">
              <NewOpSantaMariaComponents sanmarias={sanmarias} />
            </Route>
            <Route exact path="/microlend">
              <MicroLendComponents />
            </Route>
            <Route exact path="/expoindo">
              <ExpoIndoComponents indos={indos} />
            </Route>
            <Route exact path="/expocebu">
              <ExpoCebuComponents cebus={cebus} />
            </Route>
            <Route exact path="/meetph">
              <MeetPHComponents />
            </Route>
            <Route exact path="/amazingrace">
              <AmazingRaceComponents amazes={amazes} />
            </Route>
            <Route exact path="/franchiseasia">
              <FranchiseAsiaComponents asias={asias} />
            </Route>
            <Route exact path="*">
              <NoMatch/>
            </Route>
          </Switch>
        </AnimatePresence>
      <Footer/>
      <SecondFooter/>
      <TopScroll/>
      <MessengerCustomerChat
        pageId={REACT_APP_PAGE_ID_KEY}
        appId={REACT_APP_ID_KEY}
      />
    </>
  );
}

export default App;
