import React from 'react';
//React-Bootsrap
import Carousel from 'react-bootstrap/Carousel'
//React Router-Dom
import { Link } from "react-router-dom";
//Images
import Boss from "../../img/microlend-boss.jpg"
import Team from "../../img/teambuilding/team9.JPG"
import SanRafael from "../../img/san-rafael.jpg"
import SanMaria from "../../img/santa-maria-14.jpg"
import FranchiseExpoIndo from "../../img/franchise-expo.jpg"
import ExpoCebu from "../../img/cebu-expo.jpg"
import MeetPH from '../../img/meet-ph.jpg'
import FranchiseAsia from '../../img/franchise-asia-ph.jpg'
import AmazingRace from '../..//img/amazing-race.jpg'
//React-Icon
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
//Style CSS
import './NewsAndEvents.css'
//Component
import SocialMediaComponents from './SocialMediaComponents';

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

const NewOpSanSantaMariaComponents = ({sanmarias}) => {
    return (
        <div className="container nae-flex" >
            <div className="nae-content-head">
                <motion.div 
                    variants={fadeAnimation}
                    initial='hidden'
                    animate='show'
                    exit='exit'  
                >
                    <h2 className="expo-title">Newly Opened Santa Maria,<span className="expo-span"> Bulacan Branch</span></h2>
                    <p className="expo-date">December 4, 2021</p>
                    <h4 className="expo-locatiWon">Santa Maria, Bulacan</h4>
                    <div>
                        <Carousel fade>
                            {sanmarias.map((sanmaria) => {
                                const {id, image} = sanmaria;
                            return <Carousel.Item key={id} interval={3000}>
                                <img
                                className="d-block carousel-img"
                                src={image}
                                alt={image}
                                />
                            </Carousel.Item>
                        })}
                        </Carousel>
                    </div>
                    <SocialMediaComponents/>
                    <div className="socmed-box">
                        <iframe title="expo-socmed" className="expo-socmed" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmamsltg%2Fposts%2F1378240339286811&show_text=true&width=500" frameBorder="0" scrolling="no"></iframe>
                    </div>
                </motion.div>
                <div className="left-right-btn">
                    <div className="next-post-btn">
                        <Link to="/newlyopened-sanrafael-branch"><HiOutlineArrowNarrowLeft/> Prev Post</Link>
                    </div>
                    <div className="next-post-btn">
                        <Link to="/microlend">Next Post <HiOutlineArrowNarrowRight/></Link>
                    </div>
                </div>
            </div>
            <div className="other-post-cont">
                <h3>Other Posts</h3>
                <div className="other-post-subcont"> 
                    <div className="other-post-box">
                        <img src={Team} alt="team-building" />
                        <div>
                            <Link to="/team-building">Summer Outing and Team Building</Link>
                            <p>June 4 - 5, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={SanRafael} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-sanrafael-branch">Newly Opened San Rafael, Bulacan Branch</Link>
                            <p>March 4, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box select-active">
                        <img src={SanMaria} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-santamaria-branch">Newly Opened Santa Maria, Bulacan Branch</Link>
                            <p>December 4, 2021</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={Boss} alt="micro-lending" />
                        <div>
                            <Link to="/microlend">Micro-lending firm gains ground by franchising</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseExpoIndo} alt="franchise expo indo" />
                        <div>
                            <Link to="/expoindo">Franchise Expo, Indonesia 2019</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                    <img src={ExpoCebu} alt="franchise expo cebu" />
                        <div>
                            <Link to="/expocebu">Franchise Expo, Cebu City 2019</Link>
                            <p>June 22, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={MeetPH} alt="meet ph" />
                        <div>
                            <Link to="/meetph">Meet PH’s first Micro Credit Financing on Franchise</Link>
                            <p>May 21, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={AmazingRace} alt="amazing race" />
                        <div>
                            <Link to="/amazingrace">AMAZING RACE 2019</Link>
                            <p>May 11, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseAsia} alt="franchise asia" />
                        <div>
                            <Link to="/franchiseasia">Franchise Asia Philippines 2019</Link>
                            <p>April 14, 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewOpSanSantaMariaComponents;