import React from 'react';
//Images
import Criteria1 from '../../img/criteria-1.jpg'
import Criteria2 from '../../img/criteria-2.jpg'
import Criteria3 from '../../img/criteria-3.jpg'
//Styles CSS
import './CriteriaSection.css';
import './AboutUsStyle.css';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const CriteriaSection = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="criteria-container container">
            <div className="criteria-img">
                <img src={Criteria1} alt="criteria 1" />
                <img src={Criteria2} alt="criteria 2" />
                <img src={Criteria3} alt="criteria 3" />
            </div>
            <div className="criteria-content">
                <h2 className="criteria-title m5-title">Criteria</h2>
                <p className="justify-p">For its franchising business, Soliman said they chose partners whose goals are aligned with them.</p>
                <p className="justify-p">“We look for willingness to be a part of an organization which primary goal is to be of help to clients because that ensures our growth,” says Soliman. He shuns shortsighted entrepreneurs who are looking for quick returns. They believe that their clients must grow with them so that if they need more capital, they will still tap LT & G.</p>
                <p className="justify-p">They have also instituted rigid daily meetings to thoroughly monitor clients, how are they performing in business and who are behind in their payments.
                They also put up a customer care specialist unit to take care of all clients. For instance, they greet their clients on their birthdays with simple birthdays cards.</p>
                <p className="justify-p">“This a very simple gesture but this is one way to keep them loyal to us as we put a premium in establishing good relations with them because it would be very easy for them to go to other lending companies or priority other lenders for their payments,” he adds.</p>
                <p className="justify-p">Every month they also conduct raffle prizes for rice and grocery items. This is to establish good relations and to instill its goal that LT & G is not just about lending but giving back because if not for them there is no LT & G also.
                “So, we also find the right balance of being of help to clients,” he adds noting how very affordable their rates are.</p>
                <p className="justify-p">Others charge very high interest rates because if some borrowers cannot pay, the high interest that they’ve collected from others would be enough already to cover for those that defaulted.</p>
                <p className="justify-p">But because of their good relations with their clients they have conscientious payors. In fact, they have over 120 percent overall collection performance. Also, loans are being paid in full in 70-80 days instead of 100 days.</p>
            </div>
        </motion.div>
    )
}

export default CriteriaSection;