import React from 'react'
import ListTestimonials from '../components/ListTestimonials';

import { motion } from 'framer-motion';
import { pageAnimation, titleAnimation } from '../components/animation';


export const Testimonials = ({people}) => {
    return (
        <>
            <motion.div
                variants={pageAnimation}
                initial='hidden'
                animate='show'
                exit='exit'  
            >
                <motion.h2 variants={titleAnimation} className="testimonials-title">Testi<span>monials</span></motion.h2>
                <motion.div variants={titleAnimation}>
                    <ListTestimonials people={people} />
                </motion.div>
            </motion.div>
        </>
    )
}

export default Testimonials;
