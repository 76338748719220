import React from 'react';
//Sidebar Component
import FrSidebar from '../FranchiseProgComponents/FrSidebar.js'
//Style CSS
import './FrProg.css';
//Images
import ContinuingMain from '../../img/continuing-main.jpg';
import Continuing1 from '../../img/continuing-1.jpg';
import Continuing2 from '../../img/continuing-2.jpg';
import Continuing3 from '../../img/continuing-3.jpg';
import Continuing4 from '../../img/continuing-4.jpg';
import Continuing5 from '../../img/continuing-5.jpg';

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'

import { Link } from "react-router-dom";

const ContinuingSupport = () => {
    return(
    <div className="container frprog-cont">
        <FrSidebar/>
        <div className="frprog-cont-info">
            <motion.div
                variants={fadeAnimation}
                initial='hidden'
                animate='show'
                exit='exit'  
            >
                <img className="frprog-img" src={ContinuingMain} alt="main" />
                <h2 className="frprog-title">CONTINUING SUPPORT</h2>
                <div className="frprog-cont-box">
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">PERIODIC BRANCH AUDIT</h3>
                        <img className="frprog-img" src={Continuing1} alt="periodic branch audit" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">REGULAR BRANCH ASSISTANCE BY AREA SUPERVISORS</h3>
                        <img className="frprog-img" src={Continuing2} alt="regular branch assistance" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">DAILY IT MONITORING AND SUPPORT</h3>
                        <img className="frprog-img" src={Continuing3} alt="daily it monitoring and support" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">REGULAR HR ASSISTANCE AND GUIDANCE</h3>
                        <img className="frprog-img" src={Continuing4} alt="regular hr assistance and guidance" />
                    </div>
                    <div className="frprog-box">
                        <h3 className="frprog-subtitle">SEMI-MONTHLY BRANCH EVALUATION WITH FRANCHISE OWNERS & MANAGERS</h3>
                        <img className="frprog-img" src={Continuing5} alt="semi-monthly branch evaluation with franchise owners and managers" />
                    </div>
                </div>
            </motion.div>
            <div className="left-right-btn">
                <div className="next-post-btn">
                    <Link to="/opening"><HiOutlineArrowNarrowLeft/> Prev</Link>
                </div>
                <div className="next-post-btn">
                    <Link to="/marketing-support">Next <HiOutlineArrowNarrowRight/></Link>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ContinuingSupport;