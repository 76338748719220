import React from 'react';
//CardGTL Component
import CardGTL from '../CardGTL';
//Style CSS
import './FranchiseMain.css'
//Image
import FranchisingImg from '../../img/franchising-img.png';

import { motion } from 'framer-motion';
import {titleAnimation, fade, fadeAnimation} from '../../components/animation';

const FranchiseMain = () => {
    return(
        <div className="container">
            <div className="fr-main-cont">
                <div className="first-cont">
                    <motion.h3 variants={titleAnimation} className="fr-main-h3">Why Franchise with</motion.h3>
                    <motion.h2 variants={titleAnimation} className="fr-main-h2">LT&G Credit Line?</motion.h2>
                    <motion.div variants={fadeAnimation} >
                        <CardGTL/>
                    </motion.div>
                </div>
                <div className="second-cont-main">
                    <div className="second-cont">
                        <motion.h2 variants={titleAnimation} className="fr-main-h2">WHAT DO YOU GET?</motion.h2>
                        <ul>
                            <motion.li variants={fade}>Use of Name, Logo, Trademark</motion.li>
                            <motion.li variants={fade}>Pre-Opening & Start-up Assistance</motion.li>
                            <motion.li variants={fade}>Site Evaluation Assistance</motion.li>
                            <motion.li variants={fade}>Franchise Operations Manual on System</motion.li>
                            <motion.li variants={fade}>Training on Business Operations, Management, Customer Service, and Local Store Marketing</motion.li>
                            <motion.li variants={fade}>Grand Opening & Marketing Support</motion.li>
                            <motion.li variants={fade}>Continuing Business Guidance</motion.li>
                        </ul>
                    </div>
                    <motion.img variants={fade} className="franchising-img" src={FranchisingImg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default FranchiseMain