import React, {useState} from 'react';
//React-Icon
import {HiChevronUp} from 'react-icons/hi';
//Style CSS
import './TopScroll.css';

const TopScroll = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 300){
            setVisible(true);
        }else{
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return(
        <button 
            className="top-btn" 
            onClick={scrollToTop}
            style={{display: visible ? 'flex' : 'none'}}
        >
            <HiChevronUp className="chev-up" />
        </button>
    )
}

export default TopScroll;