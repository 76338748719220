import React from 'react';
import FranchiseMain from '../components/FranchiseComponents/FranchiseMain.js'
import FranchiseProg from '../components/FranchiseProg.js'
import Franchise2nd from '../components/FranchiseComponents/Franchise2nd.js';
import Franchise3rd from '../components/FranchiseComponents/Franchise3rd.js';

import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animation';

const Franchising = () => {
    return (
        <>
            <motion.div
                variants={pageAnimation}
                initial='hidden'
                animate='show'
                exit='exit'
            >
                <FranchiseMain/>
                <FranchiseProg/>
                <Franchise2nd/>
                <Franchise3rd/>
            </motion.div>
        </>
    )
}

export default Franchising