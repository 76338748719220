import React from 'react';
//Sidebar Component
import FrSidebar from '../FranchiseProgComponents/FrSidebar.js'
//Style CSS
import './FrProg.css';
//Images
import PreOpMain from '../../img/santa-maria-14.jpg';
import PreOp1 from '../../img/pre-op-1.jpg';
import PreOp2 from '../../img/pre-op-2.jpg';
import PreOp3 from '../../img/pre-op-3.jpg';
import PreOp4 from '../../img/pre-op-4.jpg';
import PreOp5 from '../../img/pre-op-5.jpg';
import PreOp6 from '../../img/pre-op-6.jpg';

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

import {HiOutlineArrowNarrowRight} from 'react-icons/hi'

import { Link } from "react-router-dom";

const PreOpening = () => {
    return(
        <div className="container frprog-cont">
            <FrSidebar/>
            <div className="frprog-cont-info">
                <motion.div
                    variants={fadeAnimation}
                    initial='hidden'
                    animate='show'
                    exit='exit'     
                >
                    <img className="frprog-img" src={PreOpMain} alt="main" />
                    <h2 className="frprog-title">PRE-OPENING SUPPORT</h2>
                    <div className="frprog-cont-box">
                        <div className="frprog-box">
                            <h3 className="frprog-subtitle">FRANCHISE AREA MAPPING & SURVEY</h3>
                            <img className="frprog-img" src={PreOp1} alt="franchise area mapping & survey" />
                        </div>
                        <div className="frprog-box">
                            <h3 className="frprog-subtitle">STAFF RECRUITMENT ACTIVITIES</h3>
                            <img className="frprog-img" src={PreOp2} alt="staff recruitment activities" />
                        </div>
                        <div className="frprog-box">
                            <h3 className="frprog-subtitle">BRANCH OFFICE SITE EVALUATION</h3>
                            <img className="frprog-img" src={PreOp3} alt="branch office site evaluation" />
                        </div>
                        <div className="frprog-box">
                            <h3 className="frprog-subtitle">CERTIFICATE COURSE IN MICRO-LENDING</h3>
                            <img className="frprog-img" src={PreOp4} alt="certificate course in micro-lending" />
                        </div>
                        <div className="frprog-box">
                            <h3 className="frprog-subtitle">ASSISTANCE ON BUSINESS REGISTRATION & LICENSING</h3>
                            <img className="frprog-img" src={PreOp5} alt="assistance on business registration & licensing" /> 
                        </div>
                        <div className="frprog-box">
                            <h3 className="frprog-subtitle">OFFICE REFURBISHMENT</h3>
                            <img className="frprog-img" src={PreOp6} alt="office refurbishment" />
                        </div>
                    </div>
                </motion.div>
                <div className="next-post-btn">
                    <Link to="/opening">Next <HiOutlineArrowNarrowRight className="arrow-right"/></Link>
                </div>
            </div>
        </div>
    )
}

export default PreOpening;