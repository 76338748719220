import Team1 from '../img/teambuilding/team1.JPG'
import Team2 from '../img/teambuilding/team2.JPG'
import Team3 from '../img/teambuilding/team3.JPG'
import Team4 from '../img/teambuilding/team4.JPG'
import Team5 from '../img/teambuilding/team5.JPG'
import Team6 from '../img/teambuilding/team6.JPG'
import Team7 from '../img/teambuilding/team7.JPG'
import Team8 from '../img/teambuilding/team8.JPG'
import Team9 from '../img/teambuilding/team9.JPG'
import Team10 from '../img/teambuilding/team10.JPG'
import Team11 from '../img/teambuilding/team11.JPG'
import Team12 from '../img/teambuilding/team12.JPG'
import Team13 from '../img/teambuilding/team13.JPG'
import Team14 from '../img/teambuilding/team14.JPG'
import Team15 from '../img/teambuilding/team15.JPG'
import Team16 from '../img/teambuilding/team16.JPG'
import Team17 from '../img/teambuilding/team17.JPG'
import Team18 from '../img/teambuilding/team18.JPG'
import Team19 from '../img/teambuilding/team19.JPG'
import Team20 from '../img/teambuilding/team20.JPG'
import Team21 from '../img/teambuilding/team21.JPG'
import Team22 from '../img/teambuilding/team22.JPG'
import Team23 from '../img/teambuilding/team23.JPG'
import Team24 from '../img/teambuilding/team24.JPG'
import Team25 from '../img/teambuilding/team25.JPG'
import Team26 from '../img/teambuilding/team26.JPG'
import Team27 from '../img/teambuilding/team27.JPG'
import Team28 from '../img/teambuilding/team28.JPG'
import Team29 from '../img/teambuilding/team29.JPG'
import Team30 from '../img/teambuilding/team30.JPG'
import Team31 from '../img/teambuilding/team31.JPG'
import Team32 from '../img/teambuilding/team32.JPG'
import Team33 from '../img/teambuilding/team33.JPG'
import Team34 from '../img/teambuilding/team34.JPG'
import Team35 from '../img/teambuilding/team35.JPG'
import Team36 from '../img/teambuilding/team36.JPG'
import Team37 from '../img/teambuilding/team37.JPG'
import Team38 from '../img/teambuilding/team38.JPG'
import Team39 from '../img/teambuilding/team39.JPG'
import Team40 from '../img/teambuilding/team40.JPG'
import Team41 from '../img/teambuilding/team41.JPG'
import Team42 from '../img/teambuilding/team42.JPG'
import Team43 from '../img/teambuilding/team43.JPG'
import Team44 from '../img/teambuilding/team44.JPG'
import Team45 from '../img/teambuilding/team45.JPG'
import Team46 from '../img/teambuilding/team46.JPG'
import Team47 from '../img/teambuilding/team47.JPG'
import Team48 from '../img/teambuilding/team48.JPG'
import Team49 from '../img/teambuilding/team49.JPG'
import Team50 from '../img/teambuilding/team50.JPG'
import Team51 from '../img/teambuilding/team51.JPG'
import Team52 from '../img/teambuilding/team52.JPG'
import Team53 from '../img/teambuilding/team53.JPG'
import Team54 from '../img/teambuilding/team54.JPG'
import Team55 from '../img/teambuilding/team55.JPG'
import Team56 from '../img/teambuilding/team56.JPG'
import Team57 from '../img/teambuilding/team57.JPG'
import Team58 from '../img/teambuilding/team58.JPG'
import Team59 from '../img/teambuilding/team59.JPG'
import Team60 from '../img/teambuilding/team60.JPG'
import Team61 from '../img/teambuilding/team61.JPG'
import Team62 from '../img/teambuilding/team62.JPG'
import Team63 from '../img/teambuilding/team63.JPG'
import Team64 from '../img/teambuilding/team64.JPG'
import Team65 from '../img/teambuilding/team65.JPG'
import Team66 from '../img/teambuilding/team66.JPG'
import Team67 from '../img/teambuilding/team67.JPG'
import Team68 from '../img/teambuilding/team68.JPG'
import Team69 from '../img/teambuilding/team69.JPG'
import Team70 from '../img/teambuilding/team70.JPG'
import Team71 from '../img/teambuilding/team71.JPG'
import Team72 from '../img/teambuilding/team72.JPG'
import Team73 from '../img/teambuilding/team73.JPG'
import Team74 from '../img/teambuilding/team74.JPG'
import Team75 from '../img/teambuilding/team75.JPG'
import Team76 from '../img/teambuilding/team76.JPG'
import Team77 from '../img/teambuilding/team77.JPG'
import Team78 from '../img/teambuilding/team78.JPG'
import Team79 from '../img/teambuilding/team79.JPG'
import Team80 from '../img/teambuilding/team80.JPG'
import Team81 from '../img/teambuilding/team81.JPG'
import Team82 from '../img/teambuilding/team82.JPG'
import Team83 from '../img/teambuilding/team83.JPG'
import Team84 from '../img/teambuilding/team84.JPG'
import Team85 from '../img/teambuilding/team85.JPG'
import Team86 from '../img/teambuilding/team86.JPG'
import Team87 from '../img/teambuilding/team87.JPG'
import Team88 from '../img/teambuilding/team88.JPG'
import Team89 from '../img/teambuilding/team89.JPG'
import Team90 from '../img/teambuilding/team90.JPG'
import Team91 from '../img/teambuilding/team91.JPG'
import Team92 from '../img/teambuilding/team92.JPG'
import Team93 from '../img/teambuilding/team93.JPG'
import Team94 from '../img/teambuilding/team94.JPG'
import Team95 from '../img/teambuilding/team95.JPG'
import Team96 from '../img/teambuilding/team96.JPG'
import Team97 from '../img/teambuilding/team97.JPG'
import Team98 from '../img/teambuilding/team98.JPG'
import Team99 from '../img/teambuilding/team99.JPG'
import Team100 from '../img/teambuilding/team100.JPG'
import Team101 from '../img/teambuilding/team101.JPG'
import Team102 from '../img/teambuilding/team102.JPG'
import Team103 from '../img/teambuilding/team103.JPG'
import Team104 from '../img/teambuilding/team104.JPG'
import Team105 from '../img/teambuilding/team105.JPG'
import Team106 from '../img/teambuilding/team106.JPG'
import Team107 from '../img/teambuilding/team107.JPG'
import Team108 from '../img/teambuilding/team108.JPG'
import Team109 from '../img/teambuilding/team109.JPG'
import Team110 from '../img/teambuilding/team110.JPG'
import Team111 from '../img/teambuilding/team111.JPG'
import Team112 from '../img/teambuilding/team112.JPG'
import Team113 from '../img/teambuilding/team113.JPG'
import Team114 from '../img/teambuilding/team114.JPG'
import Team115 from '../img/teambuilding/team115.JPG'
import Team116 from '../img/teambuilding/team116.JPG'
import Team117 from '../img/teambuilding/team117.JPG'
import Team118 from '../img/teambuilding/team118.JPG'
import Team119 from '../img/teambuilding/team119.JPG'
import Team120 from '../img/teambuilding/team120.JPG'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: Team1
    },
    {
        id: 2,
        image: Team2
    },
    {
        id: 3,
        image: Team3
    },
    {
        id: 4,
        image: Team4
    },
    {
        id: 5,
        image: Team5
    },
    {
        id: 6,
        image: Team6
    },
    {
        id: 7,
        image: Team7
    },
    {
        id: 8,
        image: Team8
    },
    {
        id: 9,
        image: Team9
    },
    {
        id: 10,
        image: Team10
    },
    {
        id: 11,
        image: Team11
    },
    {
        id: 12,
        image: Team12
    },
    {
        id: 13,
        image: Team13
    },
    {
        id: 14,
        image:Team14
    },
    {
        id: 15,
        image: Team15
    },
    {
        id: 16,
        image: Team16
    },
    {
        id: 17,
        image: Team17
    },
    {
        id: 18,
        image: Team18
    },
    {
        id: 19,
        image: Team19
    },
    {
        id: 20,
        image: Team20
    },
    {
        id: 21,
        image: Team21
    },
    {
        id: 22,
        image: Team22
    },
    {
        id: 23,
        image: Team23
    },
    {
        id: 24,
        image: Team24
    },
    {
        id: 25,
        image: Team25
    },
    {
        id: 26,
        image: Team26
    },
    {
        id: 27,
        image: Team27
    },
    {
        id: 28,
        image: Team28
    },
    {
        id: 29,
        image: Team29
    },
    {
        id: 30,
        image: Team30
    },
    {
        id: 31,
        image: Team31
    },
    {
        id: 32,
        image: Team32
    },
    {
        id: 33,
        image: Team33
    },
    {
        id: 34,
        image: Team34
    },
    {
        id:35,
        image: Team35
    },
    {
        id: 36,
        image: Team36
    },
    {
        id: 37,
        image: Team37
    },
    {
        id: 38,
        image: Team38
    },
    {
        id: 39,
        image: Team39
    },
    {
        id: 40,
        image: Team40
    },
    {
        id: 41,
        image: Team41
    },
    {
        id: 42,
        image: Team42
    },
    {
        id: 43,
        image: Team43
    },
    {
        id: 44,
        image: Team44
    },
    {
        id: 45,
        image: Team45
    },
    {
        id: 46,
        image: Team46
    },
    {
        id: 47,
        image: Team47
    },
    {
        id: 48,
        image: Team48
    },
    {
        id: 49,
        image: Team49
    },
    {
        id: 50,
        image: Team50
    },
    {
        id: 51,
        image: Team51
    },
    {
        id: 52,
        image: Team52
    },
    {
        id: 53,
        image: Team53
    },
    {
        id: 54,
        image: Team54
    },
    {
        id: 55,
        image: Team55
    },
    {
        id: 56,
        image: Team56
    },
    {
        id: 57,
        image: Team57
    },
    {
        id: 58,
        image: Team58
    },
    {
        id: 59,
        image: Team59
    },
    {
        id: 60,
        image: Team60
    },
    {
        id: 61,
        image: Team61
    },
    {
        id: 62,
        image: Team62
    },
    {
        id: 63,
        image: Team63
    },
    {
        id: 64,
        image: Team64
    },
    {
        id: 65,
        image: Team65
    },
    {
        id: 66,
        image: Team66
    },
    {
        id: 67,
        image: Team67
    },
    {
        id: 68,
        image: Team68
    },
    {
        id: 69,
        image: Team69
    },
    {
        id: 70,
        image: Team70
    },
    {
        id: 71,
        image: Team71
    },
    {
        id: 72,
        image: Team72
    },
    {
        id: 73,
        image: Team73
    },
    {
        id: 74,
        image: Team74
    },
    {
        id: 75,
        image: Team75
    },
    {
        id: 76,
        image: Team76
    },
    {
        id: 77,
        image: Team77
    },
    {
        id: 78,
        image: Team78
    },
    {
        id: 79,
        image: Team79
    },
    {
        id: 80,
        image: Team80
    },
    {
        id: 81,
        image: Team81
    },
    {
        id: 82,
        image: Team82
    },
    {
        id: 83,
        image: Team83
    },
    {
        id: 84,
        image: Team84
    },
    {
        id: 85,
        image: Team85
    },
    {
        id: 86,
        image: Team86
    },
    {
        id: 87,
        image: Team87
    },
    {
        id: 88,
        image: Team88
    },
    {
        id: 89,
        image: Team89
    },
    {
        id: 90,
        image: Team90
    },
    {
        id: 91,
        image: Team91
    },
    {
        id: 92,
        image: Team92
    },
    {
        id: 93,
        image: Team93
    },
    {
        id: 94,
        image: Team94
    },
    {
        id: 95,
        image: Team95
    },
    {
        id: 96,
        image: Team96
    },
    {
        id: 97,
        image: Team97
    },
    {
        id: 98,
        image: Team98
    },
    {
        id: 99,
        image: Team99
    },
    {
        id: 100,
        image: Team100
    },
    {
        id: 101,
        image: Team101
    },
    {
        id: 102,
        image: Team102
    },
    {
        id: 103,
        image: Team103
    },
    {
        id: 104,
        image: Team104
    },
    {
        id: 105,
        image: Team105
    },
    {
        id: 106,
        image: Team106
    },
    {
        id: 107,
        image: Team107
    },
    {
        id: 108,
        image: Team108
    },
    {
        id: 109,
        image: Team109
    },
    {
        id: 110,
        image: Team110
    },
    {
        id: 111,
        image: Team111
    },
    {
        id: 112,
        image: Team112
    },
    {
        id: 113,
        image: Team113
    },
    {
        id: 114,
        image: Team114
    },
    {
        id: 115,
        image: Team115
    },
    {
        id: 116,
        image: Team116
    },
    {
        id: 117,
        image: Team117
    },
    {
        id: 118,
        image: Team118
    },
    {
        id: 119,
        image: Team119
    },
    {
        id: 120,
        image: Team120
    },
];