import React from "react";
import AboutUsSection from '../components/AboutComponents/AboutUsSection';
import CompanySection from '../components/AboutComponents/CompanySection';
import FranchiseSection from '../components/AboutComponents/FranchiseSection';
import CriteriaSection from '../components/AboutComponents/CriteriaSection';
import SuccessSection from '../components/AboutComponents/SuccessSection';
import GrowthSection from "../components/AboutComponents/GrowthSection";
import BlessedSection from "../components/AboutComponents/BlessedSection";
import MissionVision from "../components/AboutComponents/MissionVision";

import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animation';

const AboutUs = () => {
    return(
        <>
            <motion.div
                variants={pageAnimation}
                initial='hidden'
                animate='show'
                exit='exit'
            >
                <AboutUsSection/>
                <CompanySection/>
                <FranchiseSection/>
                <CriteriaSection/>
                <SuccessSection/>
                <GrowthSection/>
                <BlessedSection/>
                <MissionVision/>
            </motion.div>
        </>
    )
}
export default AboutUs;