import React from 'react';
//react router dom 
import { NavLink } from 'react-router-dom';
//Icon
import {BsFillTelephoneFill} from 'react-icons/bs'
import {MdEmail} from 'react-icons/md'
//Style CSS
import './FrSidebar.css';

const FrSidebar = () => {
    return(
        <div className="sidebar-cont">
            <div className="sidebar-box">
                <NavLink className="fr-link" activateclassname="is-active" exact={true} to="/preopening">Pre-Opening Support</NavLink>
                <NavLink className="fr-link" exact={true} to="/opening">Opening Support</NavLink>
                <NavLink className="fr-link" exact to="/continuing-support">Continuing Support</NavLink>
                <NavLink className="fr-link" exact to="/marketing-support">Marketing Support</NavLink>
                <NavLink className="fr-link" exact to="/franchise-training">Franchise Training</NavLink>
            </div>
            <div>
                <div className="sidebar-contact-box">
                    <div className="sidebar-contact-flex">
                        <BsFillTelephoneFill className="fr-icon"/>
                        <div>
                            <p>Phone: </p>
                            <p>+63 977 360 1969</p>
                        </div>
                    </div>
                    <div className="sidebar-contact-flex">
                        <MdEmail className="fr-icon"/>
                        <div>
                            <p>Email: </p>
                            <p>john@mamsltg.com</p>
                        </div>
                    </div>
                </div>
                <div className="sidebar-contact-box">
                    <div className="sidebar-contact-flex">
                        <BsFillTelephoneFill className="fr-icon"/>
                        <div>
                            <p>Phone: </p>
                            <p>+63 936 242 7601</p>
                        </div>
                    </div>
                    <div className="sidebar-contact-flex">
                        <MdEmail className="fr-icon"/>
                        <div>
                            <p>Email: </p>
                            <p>agnes@mamsltg.com</p>
                        </div>
                    </div>
                </div>
                <div className="sidebar-contact-box">
                    <div className="sidebar-contact-flex">
                        <BsFillTelephoneFill className="fr-icon"/>
                        <div>
                            <p>Phone: </p>
                            <p>+63 965 098 5100</p>
                        </div>
                    </div>
                    <div className="sidebar-contact-flex">
                        <MdEmail className="fr-icon"/>
                        <div>
                        <p>Email: </p>
                        <p>ltgcreditline.hr@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrSidebar