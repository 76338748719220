import React from 'react';
//Style CSS
import './MissionVision.css';
import './AboutUsStyle.css';

import { motion } from 'framer-motion';
import { scrollReveal } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const MissionVision = () => {
    const [element, controls] = useScroll();
    return(
        <div className="mv-bg">
            <motion.div variants={scrollReveal} animate={controls} initial="hidden" ref={element} className="mv-container container">
                <div className="mv-content">
                    <h1 className="mv-title">Our Mission</h1>
                    <p className="justify-p">To be a <span className="mv-bold">HOME TO ENTREPRENEURS.</span></p>
                    <p className="justify-p">We will strive to provide a support system for our clients so that they can successfully grow their business through Market penetration, Job creation and Capital accumulation.</p>
                    <p className="justify-p">We will also <span className="mv-bold">CONSTANTLY FIND THE RIGHT BALANCE</span> in our operation to provide a <span className="mv-bold">REASONABLE RETURN TO OUR SHAREHOLDERS.</span></p>
                </div>
                <div className="mv-content">
                    <h1 className="mv-title">Our Vision</h1>
                    <p className="justify-p">To be a leader in growing business beyond the Bicol Region through MICRO-FINANCING and provide businesses and marketing information essential to planning, operating and growing a successful business to our clients.</p>
                    <p className="justify-p">(BUSINESS DEVELOPMENT SERVICES)</p>
                </div>
            </motion.div>
        </div>
    )
}

export default MissionVision;