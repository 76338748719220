import React from 'react';
import HeadOffice from '../components/TeamComponents/HeadOffice.js';
import HRDept from '../components/TeamComponents/HRDept.js';
import ITDept from '../components/TeamComponents/ITDept.js';

import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animation';

const Team = () => {
    return(
        <motion.div
            variants={pageAnimation}
            initial='hidden'
            animate='show'
            exit='exit'
        >
            <HeadOffice/>
            <HRDept/>
            <ITDept/>
        </motion.div>
    )
}

export default Team