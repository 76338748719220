import React from 'react';
//Style CSS
import './NewsAndEvents.css'

const SocialMediaComponents = () => {
    const fbmamshandleClick = () => {
        window.open("https://www.facebook.com/mamsltg/")
    }

    const fbltghandleClick = () => {
        window.open("https://www.facebook.com/ltgcreditlinecorp/")
    }

    return (
        <div className="social-med">
            <p>Follow us on <span>Facebook</span>.</p>
            <p className="social-med-btn" onClick={fbmamshandleClick}>MAMS LT&G Franchising Corp.</p>
            <p className="social-med-btn" onClick={fbltghandleClick}>LT&G Credit Line Corp.</p>
        </div>
    )
}

export default SocialMediaComponents;