import Amaze1 from '../img/amaze-1.jpg';
import Amaze2 from '../img/amaze-2.jpg';
import Amaze3 from '../img/amaze-3.jpg';
import Amaze4 from '../img/amaze-4.jpg';
import Amaze5 from '../img/amaze-5.jpg';
import Amaze6 from '../img/amaze-6.jpg';
import Amaze7 from '../img/amaze-7.jpg';
import Amaze8 from '../img/amaze-8.jpg';
import Amaze9 from '../img/amaze-9.jpg';
import Amaze10 from '../img/amaze-10.jpg';
import Amaze11 from '../img/amaze-11.jpg';
import Amaze12 from '../img/amaze-12.jpg';
import Amaze13 from '../img/amaze-13.jpg';
import Amaze14 from '../img/amaze-14.jpg';
import Amaze15 from '../img/amaze-15.jpg';
import Amaze16 from '../img/amaze-16.jpg';
import Amaze17 from '../img/amaze-17.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: Amaze1
    },
    {
        id: 2,
        image: Amaze2
    },
    {
        id: 3,
        image: Amaze3
    },
    {
        id: 4,
        image: Amaze4
    },
    {
        id: 5,
        image: Amaze5
    },
    {
        id: 6,
        image: Amaze6
    },
    {
        id: 7,
        image: Amaze7
    },
    {
        id: 8,
        image: Amaze8
    },
    {
        id: 9,
        image: Amaze9
    },
    {
        id: 10,
        image: Amaze10
    },
    {
        id: 11,
        image: Amaze11
    },
    {
        id: 12,
        image: Amaze12
    },
    {
        id: 13,
        image: Amaze13
    },
    {
        id: 14,
        image: Amaze14
    },
    {
        id: 15,
        image: Amaze15
    },
    {
        id: 16,
        image: Amaze16
    },
    {
        id: 17,
        image: Amaze17
    }
];