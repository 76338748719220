import React, {useRef} from 'react';
//emailjs
import emailjs from 'emailjs-com';
//style css
import './FormSection.css';
//modal sweetalert2
import Swal from 'sweetalert2'

import { motion } from 'framer-motion';
import {titleAnimation, fade} from '../../components/animation'

const FormSection = ({service, template, user}) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(service, template, e.target, user)
          .then((result) => {
              console.log(result.text);
              Swal.fire('Successfully Submit')
          }, (error) => {
              console.log(error.text);
              Swal.fire('Submit Failed, Please Try Again')
          });
          e.target.reset();
          
      };

    return(
        <div className="container email-cont">
            <div className="email-cont-title">
                <motion.h3 className="email-h3" variants={titleAnimation}>Get in touch</motion.h3>
                <motion.h2 className="email-h2" variants={titleAnimation}>With US...</motion.h2>
            </div>
            <p className="email-p">Fields marked with an * are required</p>
            <motion.form variants={fade} ref={form} className="row g-3" onSubmit={sendEmail}>
                <div className="col-md-4">
                    <label className="form-label">First name *</label>
                    <input type="text" className="form-control" name="first_name" required></input>
                </div>
                <div className="col-md-4">
                    <label className="form-label">Last name *</label>
                    <input type="text" className="form-control" name="last_name" required></input>
                </div>
                <div className="col-md-4">
                    <label htmlFor="validationServerUsername" className="form-label">Email *</label>
                    <input type="email" className="form-control" name="email" required></input>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Phone Number *</label>
                    <input type="number" className="form-control" name="number" required></input>
                </div>
                <div className="mb-3">
                    <label className="form-label">Message *</label>
                    <textarea className="form-control" name="message" required></textarea>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary email-btn" type="submit">Submit Now</button>
                </div>
            </motion.form>
        </div>
    )
}

export default FormSection