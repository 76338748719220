import FranchiseAsia1 from '../img/fr-asia-1.jpg';
import FranchiseAsia2 from '../img/fr-asia-2.jpg';
import FranchiseAsia3 from '../img/fr-asia-3.jpg';
import FranchiseAsia4 from '../img/fr-asia-4.jpg';
import FranchiseAsia5 from '../img/fr-asia-5.jpg';
import FranchiseAsia6 from '../img/fr-asia-6.jpg';
import FranchiseAsia7 from '../img/fr-asia-7.jpg';
import FranchiseAsia8 from '../img/fr-asia-8.jpg';
import FranchiseAsia9 from '../img/fr-asia-9.jpg';
import FranchiseAsia10 from '../img/fr-asia-10.jpg';
import FranchiseAsia11 from '../img/fr-asia-11.jpg';
import FranchiseAsia12 from '../img/fr-asia-12.jpg';
import FranchiseAsia13 from '../img/fr-asia-13.jpg';
import FranchiseAsia14 from '../img/fr-asia-14.jpg';
import FranchiseAsia15 from '../img/fr-asia-15.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: FranchiseAsia1
    },
    {
        id: 2,
        image: FranchiseAsia2
    },
    {
        id: 3,
        image: FranchiseAsia3
    },
    {
        id: 4,
        image: FranchiseAsia4
    },
    {
        id: 5,
        image: FranchiseAsia5
    },
    {
        id: 6,
        image: FranchiseAsia6
    },
    {
        id: 7,
        image: FranchiseAsia7
    },
    {
        id: 8,
        image: FranchiseAsia8
    },
    {
        id: 9,
        image: FranchiseAsia9
    },
    {
        id: 10,
        image: FranchiseAsia10
    },
    {
        id: 11,
        image: FranchiseAsia11
    },
    {
        id: 12,
        image: FranchiseAsia12
    },
    {
        id: 13,
        image: FranchiseAsia13
    },
    {
        id: 14,
        image: FranchiseAsia14
    },
    {
        id: 15,
        image: FranchiseAsia15
    }
];