import Paolo from '../img/paolo.png';
import Edwin from '../img/edwin.png';
import Miguel from '../img/miguel.png';
import Cyril from '../img/cyril.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        name: 'PAOLO JOSE S. BOBIER',
        testimonials: 'The system is automated, user-friendly and it is also easy to be monitored, which made it very convenient to the staffs. What makes a franchise impressive is that they are helping other people to enrich their lives. The head office as well as the area supervisor are present to give their support especially now that we are currently experiencing a pandemic, they give advises and tips that helped us in this kind of situation. I am more than happy to be a franchisee owner of LT&G, I can say that you’re safe in hands.',
        image: Paolo,
    },
    {
        id: 2,
        name: 'EDWIN D. AMURAO',
        testimonials: 'The mission of LT&G is to support and help businesses. Its distinctive feature is to help and guide small businesses to its growth from starting the business until it reaches the point where it generates larger profits. On the other hand when there are unrest or troubles that arises, the area supervisor and staffs are there to compliment our daily operation which made it manageable, we value the full support of LT&G group from Sir Marco, Ma’am Agnes and the staff down the line to the success of our business from the opening until today.',
        image: Edwin,
    },
    {
        id: 3,
        name: 'MIGUEL ANGEL U. LAZARO',
        testimonials: 'I am glad that we are guided properly by LT&G which results in making the income go beyond to what we expect. Until now we are getting the same amount of aid and support that we get from the beginning… in fact, it surpassed our expectations in this year and a half.',
        image: Miguel,
    },
    {
        id: 4,
        name: 'CYRIL O. PESAYCO',
        testimonials: 'LT&G has the strongest business model among franchise players in the Philippine micro-lending industry and we are privileged to be a franchisee and partner. Among attributes that set LT&G apart include: the specific and well thought of target client base, their well-designed standards and procedures with embedded controls and cross-checks, and a robust client credit risk process. These elements allow the franchisee to grow the business carefully and responsibly while efficiently managing various risks. The best attribute though is a franchisor whose culture and professional approach to working closely with franchisees allow for a proactive, effective, mutually productive, stable, and long-term relationship. Our core values of responsible profiting while helping small businesses prosper and succeed align strongly with LT&G’s values. LT&G is a true business partner!',
        image: Cyril,
    },
];