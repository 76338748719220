import SanRafael1 from '../img/san-rafael-1.jpg';
import SanRafael2 from '../img/san-rafael-2.jpg';
import SanRafael3 from '../img/san-rafael-3.jpg';
import SanRafael4 from '../img/san-rafael-4.jpg';
import SanRafael5 from '../img/san-rafael-5.jpg';
import SanRafael6 from '../img/san-rafael-6.jpg';
import SanRafael7 from '../img/san-rafael-7.jpg';
import SanRafael8 from '../img/san-rafael-8.jpg';
import SanRafael9 from '../img/san-rafael-9.jpg';
import SanRafael10 from '../img/san-rafael-10.jpg';
import SanRafael11 from '../img/san-rafael-11.jpg';
import SanRafael12 from '../img/san-rafael-12.jpg';
import SanRafael13 from '../img/san-rafael-13.jpg';
import SanRafael14 from '../img/san-rafael-14.jpg';
import SanRafael15 from '../img/san-rafael-15.jpg';
import SanRafael16 from '../img/san-rafael-16.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: SanRafael1
    },
    {
        id: 2,
        image: SanRafael2
    },
    {
        id: 3,
        image: SanRafael3
    },
    {
        id: 4,
        image: SanRafael4
    },
    {
        id: 5,
        image: SanRafael5
    },
    {
        id: 6,
        image: SanRafael6
    },
    {
        id: 7,
        image: SanRafael7
    },
    {
        id: 8,
        image: SanRafael8
    },
    {
        id: 9,
        image: SanRafael9
    },
    {
        id: 10,
        image: SanRafael10
    },
    {
        id: 11,
        image: SanRafael11
    },
    {
        id: 12,
        image: SanRafael12
    },
    {
        id: 13,
        image: SanRafael13
    },
    {
        id: 14,
        image: SanRafael14
    },
    {
        id: 15,
        image: SanRafael15
    },
    {
        id: 16,
        image: SanRafael16
    },
];