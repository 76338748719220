import React from "react";
//Image
import Pres from '../../img/Sir-Marco.jpg';
//Style CSS
import './AboutUsSection.css';
import './AboutUsStyle.css';

import { motion } from 'framer-motion';
import { titleAnimation, fade } from '../../components/animation';

const AboutUsSection = () => {
    return(
        <div className="about-container container">
            <div className="about-info m5-title">
                <motion.h3 variants={titleAnimation} className="info-title-h3">About LT&G Credit Line</motion.h3>
                <motion.h2 variants={titleAnimation} className="info-title m5-title">Meet PH's first <span className="info-span">Micro Credit Financing on Franchise</span></motion.h2>
                <motion.div variants={titleAnimation}>
                    <p className="justify-p">
                        Micro lending in this country is often associated with five-six practices by loan sharks, who charge very high interest rates. Instead of helping those in need, they buried these poor borrowers in deeper debts for a long time. But the unbanked Filipinos continue to patronize these micro lenders because it is easy, quick and informal.</p>
                    <p className="justify-p">
                        Based in Legazpi, Albay, entrepreneur <span className="pres-bold">Marco Antonio M. Soliman</span> has joined the fray by creating a corporation LT&G Credit Line Corp. to operate a lending company.
                    </p>
                    <p className="justify-p">
                        With his mantra: “Better Loans. Better Lives,” Soliman seeks to fill the capital requirement needs of micro enterprises at very affordable rates. Soliman also defied all odds to become the country’s first micro lending being offered on franchise.
                    </p>
                </motion.div>
            </div>
            <div className="about-pres-cont">
                <motion.img variants={fade} className="about-pres" src={Pres} alt="Sir Marco" />
            </div>
        </div>
    )
}

export default AboutUsSection;