import Cebu1 from '../img/expocebu-1.jpg';
import Cebu2 from '../img/expocebu-2.jpg';
import Cebu3 from '../img/expocebu-3.jpg';
import Cebu4 from '../img/expocebu-4.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: Cebu1
    },
    {
        id: 2,
        image: Cebu2
    },
    {
        id: 3,
        image: Cebu3
    },
    {
        id: 4,
        image: Cebu4
    }
];